/**
 * Yeti Giveaway Landing Page
 */

import React, { Component } from "react"
import { Helmet } from 'react-helmet'
import { StaticImage } from "gatsby-plugin-image"

import LayoutLander from "../components/layoutLander"
import Seo from "../components/seo"
import "../scss/pages/yetigiveaway.scss"

class YetiGiveawayThankYouPage extends Component {

  componentDidMount() {
    // Replacing &reg; elements
    let regElements = document.querySelectorAll(
      "main h2, main h3, main p, main .btn, .card__desc, .hero__title"
    )
    regElements.forEach(function (el) {
      el.innerHTML = el.innerHTML.replace(/®/g, '<sup>&reg;</sup>');
    })
  }

  render() {

    return (
      <LayoutLander bodyClass="yetigiveaway yetigiveaway-confirmation" showHeader={false} showFooter={false}>
        <Helmet>
          <meta name="theme-color" content="#0C9F50" />
        </Helmet>
        <Seo
          title="Thank You | YETI® Cooler Giveaway"
          description="Win a YETI® Tundra® 65 Hard Cooler from SIMPAS-applied Solutions."
		      robots="noindex, nofollow"
        />

        <header className="yetigiveaway-header hero bg--green-dark bg-img">
          <div className="container text-center flow--thick">
            <div className="row">
              <div className="col img-col img-left">
                  <StaticImage
                    src="../images/landing/yetigiveaway/logo_simpas_trimble_white.png"
                    alt="SIMPAS logo"
                    className="gs__img"
                  />
              </div>
              <div className="col img-col img-right">
                  <StaticImage
                    src="../images/landing/yetigiveaway/logo_PTx_white.png"
                    alt="PTx Trimble logo"
                    className="gs__img"
                  />
              </div>
            </div>
          </div>
        </header>

        <section className="yetigiveaway-hero bg--green">
          <div className="container flow--thick">
            <div className="row">
              <div className="col col-6 text-left">
                <h1>Thank you!</h1>
              </div>
              <div className="col col-6 img-col">
                  <StaticImage
                    src="../images/landing/yetigiveaway/yeti-cooler.png"
                    alt="A white YETI® Cooler with SIMPAS branding."
                    className="gs__img"
                  />
              </div>
            </div>
          </div>
        </section>

        <section className="yetigiveaway-content bg--green">
          <div className="container flow--thick">
            <div className="row">
              <div className="col col-12 text-left">
                <p>Thanks for entering to win a YETI® Tundra 65 Hard Cooler from Precision Planting® and&nbsp;SIMPAS®.</p>
                <p>Remember, the drawing will happen after your tour today. You must be present to&nbsp;win.</p>
                <p>If you'd like to learn more about how SIMPAS can help you save money on inputs, save time in the field and help increase your yield, visit&nbsp;<a href="https://www.simpas.com/" target="_blank">SIMPAS.com</a>.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="yetigiveaway-form bg--green contact-content">
          <div className="container flow">
              <div className="form-wrapper">
                <svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M42.5 20.9896V22.8296C42.4975 27.1424 41.101 31.3389 38.5187 34.7932C35.9363 38.2475 32.3066 40.7746 28.1707 41.9974C24.0348 43.2202 19.6145 43.0734 15.5689 41.5788C11.5233 40.0841 8.06929 37.3218 5.72191 33.7037C3.37452 30.0857 2.25957 25.8057 2.54334 21.5022C2.82711 17.1987 4.4944 13.1022 7.29654 9.82374C10.0987 6.54523 13.8856 4.26036 18.0924 3.30989C22.2992 2.35941 26.7005 2.79427 30.64 4.54959M42.5 6.82959L22.5 26.8496L16.5 20.8496" stroke="#0C9F50" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p className="confirmation-text">You have entered!</p>
              </div>
          </div>
        </section>

        <section className="yetigiveaway-rules bg--green">
          <div className="container flow">
            <div className="row">
              <div className="col col-md-12 text-left">
                <h2>Giveaway Rules</h2>
                <p>This giveaway is limited to the Precision Planting Field Days attendees at PTI Farm in Pontiac, IL. The events begin on July 29 and end on August 16,&nbsp;2024.</p>
                <ul>
                  <li>Winners must be present to win and be 18&nbsp;or&nbsp;older.</li>
                  <li>No purchase is required.</li>
                  <li>Only one entry per person, per email address is&nbsp;allowed.</li>
                  <li>A third-party agency in Memphis, Tennessee, will randomly select the&nbsp;winners.</li>
                  <li>Draws will be made at 4:00 p.m. and 5:30 p.m. daily from July 29 to August 2 and August 12 to 16,&nbsp;2024.</li>
                  <li>A total of twenty (20) coolers will be given&nbsp;away.</li>
                  <li>Odds of winning are dependent on the number of entries&nbsp;received.</li>
                  <li>Approximate retail value of each prize is&nbsp;$525.</li>
                  <li>Prizes are not transferable, and substitutions are prohibited except at the sponsors'&nbsp;discretion.</li>
                  <li>Prizes have no cash&nbsp;value.</li>
                  <li>Prize will be shipped via ground courier within two weeks of the winners' selection at no cost to the&nbsp;winner.</li>
                  <li>Winners are responsible for taxes and any other costs associated with claiming&nbsp;prizes.</li>
                  <li>For a list of all winners, email info@simpas.com.</li>
                  <li>Void where prohibited by&nbsp;law.</li>
                </ul>
                <p className="policy-link"><a href="https://www.amvac.com/privacy-statement" target="_blank" rel="noreferrer">Review our privacy policy</a></p>
              </div>
            </div>
          </div>
        </section>

        <section className="yetigiveaway-divider bg--green">
          <div className="container flow">
            <hr/>
          </div>
        </section>

        <footer className="yetigiveaway-disclaimer bg--green">
          <div className="container flow">
            <div className="row">
              <p>©2024 All rights reserved. AMVAC Chemical Corporation is a wholly owned subsidiary of American Vanguard Corporation and Precision Planting is an AGCO company.</p>
              <p>SIMPAS®, SIMPAS-applied Solutions® and respective logos are trademarks owned by AMVAC Chemical Corporation; Precision Planting® is a trademark owned by Precision Planting LLC; and YETI® is a trademark owned by YETI® Coolers&nbsp;LLC</p>
            </div>
          </div>
        </footer>

      </LayoutLander>
    )
  }
}
export default YetiGiveawayThankYouPage
